<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox" >
        
        <div class="title" @mousedown="move">
            <div>
                <span class="title-content" >
                视频监控
                </span>
                <span class="caoz">
                    <i class="iconfont icon-shanchu2" style="float: right; padding-right: 10px;" @click="close()"></i>
                    <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
                </span>
                   
                
            </div>
        </div>
           <div class="bigImg">
          <el-carousel :interval="0" arrow="always" :autoplay="false" indicator-position="none" style="height:400px" ref="carousel">
                <el-carousel-item v-for="item,key in imgList" :key="key">
                <div class="imgList">
                <div v-for="items,keys in item" :key="keys" class="imgbox">
                    <!-- <a  @click="handlePreView(items)" target="_blank">
                    <img :src="items" style="width:257px;height:144px" />
                    </a> -->
                    <!-- <video
                    
                    oncontextmenu="return false"
                    controlslist="nodownload"
                    class="video-box"
                  ></video> -->
                  <vueMiniPlayer ref="vueMiniPlayer" :video="items.video" :mutex="true" @fullscreen="handleFullscreen" />
                </div>
                  </div>
                </el-carousel-item>
            </el-carousel>
       </div>
        </div>
         
        </div>
    
</template>
<script>
import { getVideoMonitorList } from '../../../RequestPort/gisbook';

export default {
  data() {
    return {
      dialogVisible: false,
      form:{pipeLineLengthVO:{},stationCountVOList:[],pipeFittingCountVO:{},projectCountVO:{}},
      imgList:[[{},{},{},{}]]
     
    }
  },
  mounted() {
  },
  methods: {
    openDialog(id) {
       
        this.imgList = []
        getVideoMonitorList({id:id}).then(res=>{
            if(res.data.length>0){
                 this.dialogVisible = true;
                var i=0 ;var sub=[]
                res.data.forEach(element => {
                  //element.video={url:'https://api.dogecloud.com/player/get.mp4?vcode=5ac682e6f8231991&userId=17&ext=.mp4'}
                  element.video={url:element.agreement}
                    i++;
                    if(i%4==0){
                        sub.push(element)
                        this.imgList.push(sub)
                         sub =[];
                    }else{
                      sub.push(element)
                      let num = res.data.length
                      if(i%4!=0&&num==i){
                        this.imgList.push(sub)
                      }
                      }
                });
            }else{
                this.$message.warning('暂无数据')
                this.imgList = [[]]
            }
        })
     
    },
    close(){
        this.dialogVisible = false;
    },
    handleFullscreen(){},
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.imgList{width: 650px; height: 400px;margin:20px}
.imgbox{
    border: #285F88 1px solid;
    width:257px;height:144px;
    display: inline-block;
    margin:5px;
}
::v-deep .el-carousel__container{height: 400px;}
.blueFormLabelClass{line-height: 30px;padding-top:5px;}
.caoz{
    color:#285F88;
}
.allColor{
    color: var(--font-color-b2);
   
}
.el-divider--horizontal{margin: 10px 0px;}
.title{
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
.title-content{color: var(--font-color-f1);font-weight: bold;padding: 13px 9px;font-size: 14px;}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
}
::v-deep .el-tabs__item {
  color: var(--font-color-c1);
}
::v-deep .el-tabs__active-bar {
  background-color: #6eb7e3;
}
::v-deep .el-tabs__nav-wrap::after {
  content: none;
}
::v-deep .el-tabs__header {
  margin: 0px;
}
::v-deep .el-button--text {
  background: transparent !important;
  border: none;
}
::v-deep .formItemSmall.el-form-item {
   margin-bottom: 0px;
}

.caozu {
  display: inline-flex;
  height: 40px;
  font-size: 14px;
  text-align: right;
  vertical-align: text-bottom;
  line-height: 36px;
  color: #a5b4c4;
  float: right;
}
.colseBut {
  font-size: 26px;
  vertical-align: middle;
  cursor: pointer;
}
.layerBox {
  width: 680px;
  position: absolute;
  top:115px;
  right:769px;
  min-height: 3rem;
  min-width: 3rem;
//   border: 1px solid var(--border-color-skin);
  box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  z-index: 20002;
  background: var(--background-color-skin);
     opacity: 0.9;
}
// .contentD{
//      background: var(--background-color-skin);
//      opacity: 0.9;
// }
.Feature {
 margin: 5px 5px 10px 0;
  line-height: 0.28rem;
  text-align: center;
  width: 100%;
  color: var(--font-color-f1);
}
.equipment-item {
  text-align: left;
  width: 380px;
  word-break: break-word;
}
.btnDiv {
  margin-bottom: 15px;
  .btn {
    margin-right: 15px;
  }
}
.formbtn {
  button {
    margin: 7px 15px 0 15px;
  }
}
.btnText {
  color: var(--font-color-f1);
  padding: 4px 5px;
}
/* table按钮颜色 */
.btnText.danger {
  color: #f74b4b;
}
.btnText.danger:hover,
.btnText.danger:focus {
  color: #f78989;
}
.selecttag {
  margin-left: 5px;
  cursor: pointer;
}
.selectWidth {
  width: 380px;
}
.bigImg{    text-align: center;
    vertical-align: middle;
    background-color:#F9FAFE;border-radius: 3px}
</style>
