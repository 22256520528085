<template>
  <div>
    <div v-show="dialogVisible" id="dragBox" ref="dragBox" class="layerBox" >
        
        <div class="title" @mousedown="move">
            <div>
                <span class="title-content" >
                {{form.stationName?form.stationName:'场站'}}
                </span>
                <span class="caoz">
                    <i class="iconfont icon-shanchu2" style="float: right; padding-right: 10px;" @click="close()"></i>
                    <i class="iconfont icon-yidong" style="float: right; padding-right:13px"></i>
                </span>
                
            </div>
        </div>
   
    <div v-if="form.stationType=='1'" style=" overflow: auto;">
      <el-form ref="form" :model="form" label-width="75px" class="desform">
        <el-form-item label="所属图层 :" prop="code">
          <span class="spanDesc">{{ form.layerName }}</span>
        </el-form-item>
        <el-form-item label="场站分类 :" prop="type">
          <span class="spanDesc">{{ form.stationKindName }}</span>
        </el-form-item>
        <!-- <el-form-item label="设计规模 :" prop="type">
              <span class="spanDesc">{{ form.scale }}(Nm³/h)</span>
        </el-form-item> -->
        <el-form-item label="地址 :" prop="type">
          <label slot="label">地       址 :</label>
              <span class="spanDesc">{{ form.address }}</span>
        </el-form-item>
        <el-form-item label="经纬度 :" prop="type">
          <label slot="label">经  纬  度 :</label>
              <span class="spanDesc">{{ postion }}</span>
        </el-form-item>
        <!-- <el-form-item label="场站地址 :" prop="type">
              <span class="spanDesc">{{ form.address }}</span>
        </el-form-item> -->
      
        <el-row v-for="item,index in form.realtimeList" :key="index">
            <el-divider content-position="left">{{item.pointName}}</el-divider>
            
            <el-form-item :label="item.channelType+'压力 :'" prop="type">
              <span class="spanDesc">{{ item.pressure }}<el-tag v-if="item.pressureWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
              
            </el-form-item>
            <el-form-item :label="item.channelType+'流量 :'" prop="type">
              <span class="spanDesc">{{ item.flow }}<el-tag v-if="item.flowWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
            </el-form-item>
        </el-row>
       
      </el-form>
      <!-- <div class="btn">
        <el-button type="primary" size="mini" @click="openVideo(form.id)">视频监控</el-button>
      </div> -->
      </div>
      <div v-else style="height: 220px; overflow: auto;">
      <el-form ref="form" :model="form" label-width="75px" class="desform">
        <el-form-item label="所属图层 :" prop="code">
          <span class="spanDesc">{{ form.layerName }}</span>
        </el-form-item>
        <el-form-item label="场站分类 :" prop="type">
          <span class="spanDesc">{{ form.stationKindName  }}</span>
        </el-form-item>
        <!-- <el-form-item label="设计规模 :" prop="type">
              <span class="spanDesc">{{ form.scale }}(Nm³/h)</span>
        </el-form-item> -->
        <el-form-item label="地址 :" prop="type">
          <label slot="label">地       址 :</label>
              <span class="spanDesc">{{ form.address }}</span>
        </el-form-item>
        <el-form-item label="经纬度 :" prop="type">
          <label slot="label">经  纬  度 :</label>
              <span class="spanDesc">{{ postion }}</span>
        </el-form-item>

        <!-- <el-form-item label="场站地址 :" prop="type">
              <span class="spanDesc">{{ form.address }}</span>
        </el-form-item> -->
        <!-- <div v-if="form.stationType==2">
        <el-form-item label="用户数量 :" prop="type">
              <span class="spanDesc">{{ form.userCount }}</span>
        </el-form-item>
        <el-form-item label="居民 :" prop="type">
          <label slot="label">居       民 :</label>
              <span class="spanDesc">{{ form.residentCount }}</span>
        </el-form-item>
        <el-form-item label="商业 :" prop="type">
          <label slot="label">商       业 :</label>
              <span class="spanDesc">{{ form.businessCount }}</span>
        </el-form-item>
        <el-form-item label="工业 :" prop="type">
          <label slot="label">工       业 :</label>
              <span class="spanDesc">{{ form.industryCount }}</span>
        </el-form-item>
        </div> -->
        <el-form-item label="备注 :" prop="type">
          <label slot="label">备       注 :</label>
              <span class="spanDesc">{{ form.remark }}</span>
        </el-form-item>
        <!-- <el-row v-for="item,index in form.realtimeList" :key="index">
            <el-divider content-position="left">{{item.pointName}}</el-divider>
            
            <el-form-item :label="item.channelType+'压力 :'" prop="type">
              <span class="spanDesc">{{ item.pressure }}<el-tag v-if="item.pressureWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
              
            </el-form-item>
            <el-form-item :label="item.channelType+'流量 :'" prop="type">
              <span class="spanDesc">{{ item.flow }}<el-tag v-if="item.flowWarn==2" style="float: right;margin-right: 20px;margin-top: 5px;" size="mini" type="danger">数据异常</el-tag></span>
            </el-form-item>
        </el-row> -->
        
       
      </el-form>
      </div>
    <!-- </el-dialog> -->
    </div>
    <video-list ref="videoList"></video-list>
  </div>
</template>
<script>
import { stationFindByIds } from '../apis/tool'
import videoList from './videoList.vue'
export default {
  components: { videoList },
  props: {
    id: {
      type: String,
      default: () => ''
    },
    postion: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      form: {},
      dialogVisible: false
    }
  },
  mounted() {},
  methods: {
    close() {
      this.dialogVisible = false
      this.form = {}
    },
    openDesc(flag) {
      let that = this
      this.dialogVisible = flag;
    },
    openD(id) {
      this.dialogVisible = true
      //获取数据的接口等待对接 
      stationFindByIds({ id:id }).then(res => {
        this.form = res.data
      })
    },
    openVideo(id){
      this.$refs.videoList.openDialog(id)
    },
    move(e) {
      let odiv = this.$refs.dragBox
      let disX = e.clientX - odiv.offsetLeft
      let disY = e.clientY - odiv.offsetTop
      document.onmousemove = e => {
        let left = e.clientX - disX
        let top = e.clientY - disY
        this.positionX = top
        this.positionY = left

        odiv.style.left = left + 'px'
        odiv.style.top = top + 'px'
      }
      document.onmouseup = e => {
        document.onmousemove = null
        document.onmouseup = null
      }
    },
  
  }
}
</script>
<style lang="scss" scoped>
.btn {
  text-align: right;
  button {
    margin: 7px 15px 0 15px;
  }
}
.spanDesc {
  text-align: left;
  width: 180px;
  color: var(--font-color-c2);
  margin-left: 5px;
  line-height: 20px;
    display: inline-block;
    vertical-align: text-top;
  
}
::v-deep .el-form-item{
  margin-bottom:0px;
}
::v-deep .el-divider--horizontal{
  margin: 10px 0px;
}
.desform{text-align: left;margin-left: 10px;margin-top: 10px;}
.title{
    height: 40px;
    line-height: 40px;
    background: linear-gradient(90deg, var(--background-title-skin1), var(--background-title-skin2));
}
.title-content{color: var(--font-color-f1);font-weight: bold;padding: 13px 17px;font-size: 14px;}
::v-deep .el-tabs__item.is-active {
  color: var(--font-color-f1);
}
.layerBox {
  position: absolute;
  top: 2.2rem;
  left: 6rem;
  
  width: 333px;
  // border: 1px solid var(--border-color-skin);
   box-shadow: 0px 1px 6px 0px rgba(4, 57, 138, 0.3);
  background: var(--background-color-skin);
  z-index: 20001;
  padding: 0px 0px 10px 0px;
  opacity: 0.98;
  overflow: auto;
}
::v-deep .el-form-item__content{color: #666666;}
</style>
